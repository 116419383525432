import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import FadeInAnimation from '../components/UX/FadeInAnimation';
import InViewTrigger from '../components/Utility/UX/InViewTrigger';

import ImgTextModule from '../components/elements/ImgTextModule';

const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Datenschutz</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      <ImgTextModule
        src={
          '34292_men_working_on_computers_in_office_By_Yigal_Ohanna_Artlist_HD.mp4'
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='65vh'
        mediaOpacity={0.7}
        bgColor='brand.gray.100'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            ></FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.green'
                fontWeight='normal'
                fontSize={{ base: '5xl', lg: '6xl' }}
              >
                Datenschutz
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.yellow'
                mb={24}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='2xl'
              >
                Wir nehmen den Schutz der Daten ernst.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

      <Box w='100%'>
        <Container variant='layoutContainer' px={10} py={20}>
          <Box w={{ lg: '50%', base: '100%' }}>
            <InViewTrigger threshold={0.4} triggerOnce={false}>
              {(inView) => (
                <Stack
                  direction={{ base: 'column', md: 'column' }}
                  spacing={4}
                  align='stretch'
                >
                  <Box>
                    <Heading size='xl'>1. Datenschutz auf einen Blick</Heading>
                    <Heading mt={4} size='lg' my={4}>
                      Allgemeine Hinweise
                    </Heading>
                    <p>
                      Die folgenden Hinweise geben einen einfachen Überblick
                      darüber, was mit Ihren personenbezogenen Daten passiert,
                      wenn Sie diese Website besuchen. Personenbezogene Daten
                      sind alle Daten, mit denen Sie persönlich identifiziert
                      werden können. Ausführliche Informationen zum Thema
                      Datenschutz entnehmen Sie unserer unter diesem Text
                      aufgeführten Datenschutzerklärung.
                    </p>
                    <Heading mt={4} size='lg' my={4}>
                      Datenerfassung auf dieser Website
                    </Heading>
                    <Heading mt={4} size='md' my={4}>
                      Wer ist verantwortlich für die Datenerfassung auf dieser
                      Website?{' '}
                    </Heading>
                    <p>
                      Die Datenverarbeitung auf dieser Website erfolgt durch den
                      Websitebetreiber. Dessen Kontaktdaten können Sie dem
                      Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser
                      Datenschutzerklärung entnehmen.
                    </p>
                    <Heading mt={4} size='md' my={4}>
                      Wie erfassen wir Ihre Daten?
                    </Heading>
                    <p>
                      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns
                      diese mitteilen. Hierbei kann es sich z. B. um Daten
                      handeln, die Sie in ein Kontaktformular eingeben.
                      <br />
                      <br />
                      Andere Daten werden automatisch oder nach Ihrer
                      Einwilligung beim Besuch der Website durch unsere
                      IT-Systeme erfasst. Das sind vor allem technische Daten
                      (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
                      Seitenaufrufs). Die Erfassung dieser Daten erfolgt
                      automatisch, sobald Sie diese Website betreten.
                    </p>
                    <Heading mt={4} size='md' my={4}>
                      Wofür nutzen wir Ihre Daten?
                    </Heading>
                    <p>
                      Ein Teil der Daten wird erhoben, um eine fehlerfreie
                      Bereitstellung der Website zu gewährleisten. Andere Daten
                      können zur Analyse Ihres Nutzerverhaltens verwendet
                      werden.
                    </p>
                    <Heading mt={4} size='md' my={4}>
                      Welche Rechte haben Sie bezüglich Ihrer Daten?
                    </Heading>
                    <p>
                      Sie haben jederzeit das Recht, unentgeltlich Auskunft über
                      Herkunft, Empfänger und Zweck Ihrer gespeicherten
                      personenbezogenen Daten zu erhalten. Sie haben außerdem
                      ein Recht, die Berichtigung oder Löschung dieser Daten zu
                      verlangen. Wenn Sie eine Einwilligung zur
                      Datenverarbeitung erteilt haben, können Sie diese
                      Einwilligung jederzeit für die Zukunft widerrufen.
                      Außerdem haben Sie das Recht, unter bestimmten Umständen
                      die Einschränkung der Verarbeitung Ihrer personenbezogenen
                      Daten zu verlangen. Des Weiteren steht Ihnen ein
                      Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                      <br />
                      <br />
                      Hierzu sowie zu weiteren Fragen zum Thema Datenschutz
                      können Sie sich jederzeit an uns wenden.
                    </p>
                    <Heading mt={4} size='xl'>
                      2. Hosting
                    </Heading>
                    <p>
                      {' '}
                      Wir hosten die Inhalte unserer Website bei folgendem
                      Anbieter:
                    </p>
                    <p>Hetzner</p>
                    <p>
                      Anbieter ist die Hetzner Online GmbH, Industriestr. 25,
                      91710 Gunzenhausen (nachfolgend Hetzner).
                    </p>
                    <br />
                    <br />
                    <p>
                      Details entnehmen Sie der Datenschutzerklärung von
                      Hetzner: <br />
                      <br />
                      <a href='https://www.hetzner.com/de/rechtliches/datenschutz'>
                        <strong>
                          https://www.hetzner.com/de/rechtliches/datenschutz
                        </strong>
                      </a>{' '}
                      <br />
                      <br />
                      Die Verwendung von Hetzner erfolgt auf Grundlage von Art.
                      6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes
                      Interesse an einer möglichst zuverlässigen Darstellung
                      unserer Website. Sofern eine entsprechende Einwilligung
                      abgefragt wurde, erfolgt die Verarbeitung ausschließlich
                      auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs.
                      1 TTDSG, soweit die Einwilligung die Speicherung von
                      Cookies oder den Zugriff auf Informationen im Endgerät des
                      Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG
                      umfasst. Die Einwilligung ist jederzeit widerrufbar.
                    </p>
                    <Heading mt={4} size='xl'>
                      3. Allgemeine Hinweise und Pflichtinformationen
                    </Heading>
                    <Heading size='lg'>Datenschutz</Heading>
                    <p>
                      {' '}
                      Die Betreiber dieser Seiten nehmen den Schutz Ihrer
                      persönlichen Daten sehr ernst. Wir behandeln Ihre
                      personenbezogenen Daten vertraulich und entsprechend den
                      gesetzlichen Datenschutzvorschriften sowie dieser
                      Datenschutzerklärung. <br />
                      <br />
                      Wenn Sie diese Website benutzen, werden verschiedene
                      personenbezogene Daten erhoben. Personenbezogene Daten
                      sind Daten, mit denen Sie persönlich identifiziert werden
                      können. Die vorliegende Datenschutzerklärung erläutert,
                      welche Daten wir erheben und wofür wir sie nutzen. Sie
                      erläutert auch, wie und zu welchem Zweck das geschieht.{' '}
                      <br />
                      <br />
                      Wir weisen darauf hin, dass die Datenübertragung im
                      Internet (z. B. bei der Kommunikation per E- Mail)
                      Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
                      der Daten vor dem Zugriff durch Dritte ist nicht möglich.{' '}
                      <br />
                      <br />
                      <strong>Hinweis zur verantwortlichen Stelle </strong>
                      <br />
                      <br />
                      Die verantwortliche Stelle für die Datenverarbeitung auf
                      dieser Website ist: <br />
                      kdw HR GmbH & Co. KG <br />
                      Ulmenstrasse 21 <br />
                      26384 Wilhelmshaven <br />
                      Telefon: 04421 80 99 00 <br />
                      E-Mail: whv@kdw-hr.de <br /> <br />
                      Verantwortliche Stelle ist die natürliche oder juristische
                      Person, die allein oder gemeinsam mit anderen über die
                      Zwecke und Mittel der Verarbeitung von personenbezogenen
                      Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.{' '}
                      <br />
                      <br />
                      <strong>Datenschutzbeauftragter</strong>
                      <br />
                      <br />
                      Wir haben einen externen Datenschutzbeauftragten benannt.
                      Diesen können Sie unter folgender E- Mail-Adresse
                      erreichen: E-Mail: datenschutz@kdw-hr.de Speicherdauer
                      Soweit innerhalb dieser Datenschutzerklärung keine
                      speziellere Speicherdauer genannt wurde, verbleiben Ihre
                      personenbezogenen Daten bei uns, bis der Zweck für die
                      Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
                      Löschersuchen geltend machen oder eine Einwilligung zur
                      Datenverarbeitung widerrufen, werden Ihre Daten gelöscht,
                      sofern wir keine anderen rechtlich zulässigen Gründe für
                      die Speicherung Ihrer personenbezogenen Daten haben (z. B.
                      steuer- oder handelsrechtliche Aufbewahrungsfristen); im
                      letztgenannten Fall erfolgt die Löschung nach Fortfall
                      dieser Gründe.
                    </p>
                    <Heading mt={4} size='xl'>
                      4. Allgemeine Hinweise zu den Rechtsgrundlagen der
                      Datenverarbeitung auf dieser Website
                    </Heading>
                    <p>
                      Sofern Sie in die Datenverarbeitung eingewilligt haben,
                      verarbeiten wir Ihre personenbezogenen Daten auf Grundlage
                      von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a
                      DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1
                      DSGVO verarbeitet werden. Im Falle einer ausdrücklichen
                      Einwilligung in die Übertragung personenbezogener Daten in
                      Drittstaaten erfolgt die Datenverarbeitung außerdem auf
                      Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in
                      die Speicherung von Cookies oder in den Zugriff auf
                      Informationen in Ihr Endgerät (z. B. via Device-
                      Fingerprinting) eingewilligt haben, erfolgt die
                      Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1
                      TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind
                      Ihre Daten zur Vertragserfüllung oder zur Durchführung
                      vorvertraglicher Maßnahmen erforderlich, verarbeiten wir
                      Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.
                      Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur
                      Erfüllung einer rechtlichen Verpflichtung erforderlich
                      sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die
                      Datenverarbeitung kann ferner auf Grundlage unseres
                      berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO
                      erfolgen. Über die jeweils im Einzelfall einschlägigen
                      Rechtsgrundlagen wird in den folgenden Absätzen dieser
                      Datenschutzerklärung informiert.
                    </p>
                    <Heading size='lg' my={4}>
                      {' '}
                      Empfänger von personenbezogenen Daten{' '}
                    </Heading>
                    <p>
                      Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit
                      verschiedenen externen Stellen zusammen. Dabei ist
                      teilweise auch eine Übermittlung von personenbezogenen
                      Daten an diese externen Stellen erforderlich. Wir geben
                      personenbezogene Daten nur dann an externe Stellen weiter,
                      wenn dies im Rahmen einer Vertragserfüllung erforderlich
                      ist, wenn wir gesetzlich hierzu verpflichtet sind (z. B.
                      Weitergabe von Daten an Steuerbehörden), wenn wir ein
                      berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an
                      der Weitergabe haben oder wenn eine sonstige
                      Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz
                      von Auftragsverarbeitern geben wir personenbezogene Daten
                      unserer Kunden nur auf Grundlage eines gültigen Vertrags
                      über Auftragsverarbeitung weiter. Im Falle einer
                      gemeinsamen Verarbeitung wird ein Vertrag über gemeinsame
                      Verarbeitung geschlossen.
                    </p>
                    <Heading size='lg' my={4}>
                      {' '}
                      Widerruf Ihrer Einwilligung zur Datenverarbeitung{' '}
                    </Heading>
                    <p>
                      Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                      ausdrücklichen Einwilligung möglich. Sie können eine
                      bereits erteilte Einwilligung jederzeit widerrufen. Die
                      Rechtmäßigkeit der bis zum Widerruf erfolgten
                      Datenverarbeitung bleibt vom Widerruf unberührt. <br />
                      <br />
                      Widerspruchsrecht gegen die Datenerhebung in besonderen
                      Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
                      <br />
                      <br /> WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6
                      ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT
                      DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
                      SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
                      PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT
                      AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
                      DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG
                      BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN
                      SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
                      PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
                      DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
                      VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND
                      FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
                      GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
                      RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
                      4 WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM
                      DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT,
                      JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
                      BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
                      WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
                      SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT.
                      WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN
                      DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
                      DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2
                      DSGVO).
                    </p>
                    <Heading size='lg' my={4}>
                      Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    </Heading>
                    <p>
                      Im Falle von Verstößen gegen die DSGVO steht den
                      Betroffenen ein Beschwerderecht bei einer
                      Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
                      gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des
                      Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
                      besteht unbeschadet anderweitiger verwaltungsrechtlicher
                      oder gerichtlicher Rechtsbehelfe.
                    </p>
                    <Heading size='lg' my={4}>
                      {' '}
                      Recht auf Datenübertragbarkeit
                    </Heading>
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
                    Einwilligung oder in Erfüllung eines Vertrags automatisiert
                    verarbeiten, an sich oder an einen Dritten in einem
                    gängigen, maschinenlesbaren Format aushändigen zu lassen.
                    Sofern Sie die direkte Übertragung der Daten an einen
                    anderen Verantwortlichen verlangen, erfolgt dies nur, soweit
                    es technisch machbar ist.
                    <Heading size='lg' my={4}>
                      {' '}
                      Auskunft, Löschung und Berichtigung
                    </Heading>
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
                    jederzeit das Recht auf unentgeltliche Auskunft über Ihre
                    gespeicherten personenbezogenen Daten, deren Herkunft und
                    Empfänger und den Zweck der Datenverarbeitung und ggf. ein
                    Recht auf Berichtigung oder Löschung dieser Daten. Hierzu
                    sowie zu weiteren Fragen zum Thema personenbezogene Daten
                    können Sie sich jederzeit an uns wenden.
                    <Heading size='lg' my={4}>
                      {' '}
                      Recht auf Einschränkung der Verarbeitung
                    </Heading>{' '}
                    Sie haben das Recht, die Einschränkung der Verarbeitung
                    Ihrer personenbezogenen Daten zu verlangen. Hierzu können
                    Sie sich jederzeit an uns wenden. Das Recht auf
                    Einschränkung der Verarbeitung besteht in folgenden Fällen:
                    Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                    personenbezogenen Daten bestreiten, benötigen wir in der
                    Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung
                    haben Sie das Recht, die Einschränkung der Verarbeitung
                    Ihrer personenbezogenen Daten zu verlangen. Wenn die
                    Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                    geschah/geschieht, können Sie statt der Löschung die
                    Einschränkung der Datenverarbeitung verlangen. Wenn wir Ihre
                    personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch
                    zur Ausübung, Verteidigung oder Geltendmachung von
                    Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                    Löschung die Einschränkung der Verarbeitung Ihrer
                    personenbezogenen Daten zu verlangen. Wenn Sie einen
                    Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss
                    eine Abwägung zwischen Ihren und unseren Interessen
                    vorgenommen werden. Solange noch nicht feststeht, wessen
                    Interessen überwiegen, haben Sie das Recht, die
                    Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                    zu verlangen. Wenn Sie die Verarbeitung Ihrer
                    personenbezogenen Daten eingeschränkt haben, dürfen diese
                    Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
                    Einwilligung oder zur Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte
                    einer anderen natürlichen oder 5 juristischen Person oder
                    aus Gründen eines wichtigen öffentlichen Interesses der
                    Europäischen Union oder eines Mitgliedstaats verarbeitet
                    werden.
                    <Heading size='lg' my={4}>
                      SSL- bzw. TLS-Verschlüsselung
                    </Heading>
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
                    Übertragung vertraulicher Inhalte, wie zum Beispiel
                    Bestellungen oder Anfragen, die Sie an uns als
                    Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung.
                    Eine verschlüsselte Verbindung erkennen Sie daran, dass die
                    Adresszeile des Browsers von „http://“ auf „https://“
                    wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
                    Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können
                    die Daten, die Sie an uns übermitteln, nicht von Dritten
                    mitgelesen werden.
                    <Heading size='lg' my={4}>
                      Widerspruch gegen Werbe-E-Mails{' '}
                    </Heading>
                    Der Nutzung von im Rahmen der Impressumspflicht
                    veröffentlichten Kontaktdaten zur Übersendung von nicht
                    ausdrücklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit widersprochen. Die
                    Betreiber der Seiten behalten sich ausdrücklich rechtliche
                    Schritte im Falle der unverlangten Zusendung von
                    Werbeinformationen, etwa durch Spam-E-Mails, vor.
                    <Heading mt={4} size='xl'>
                      5. Datenerfassung auf dieser Website
                    </Heading>
                    <Heading my={4} size='lg'>
                      Cookies
                    </Heading>
                    Unsere Internetseiten verwenden so genannte „Cookies“.
                    Cookies sind kleine Datenpakete und richten auf Ihrem
                    Endgerät keinen Schaden an. Sie werden entweder
                    vorübergehend für die Dauer einer Sitzung (Session-Cookies)
                    oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
                    gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
                    automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
                    Endgerät gespeichert, bis Sie diese selbst löschen oder eine
                    automatische Löschung durch Ihren Webbrowser erfolgt. <br />
                    <br />
                    Cookies können von uns (First-Party-Cookies) oder von
                    Drittunternehmen stammen (sog. Third-Party- Cookies).
                    Third-Party-Cookies ermöglichen die Einbindung bestimmter
                    Dienstleistungen von Drittunternehmen innerhalb von
                    Webseiten (z. B. Cookies zur Abwicklung von
                    Zahlungsdienstleistungen). <br />
                    <br />
                    Cookies haben verschiedene Funktionen. Zahlreiche Cookies
                    sind technisch notwendig, da bestimmte Webseitenfunktionen
                    ohne diese nicht funktionieren würden (z. B. die
                    Warenkorbfunktion oder die Anzeige von Videos). Andere
                    Cookies können zur Auswertung des Nutzerverhaltens oder zu
                    Werbezwecken verwendet werden. <br />
                    <br />
                    Cookies, die zur Durchführung des elektronischen
                    Kommunikationsvorgangs, zur Bereitstellung bestimmter, von
                    Ihnen erwünschter Funktionen (z. B. für die
                    Warenkorbfunktion) oder zur Optimierung der Website (z. B.
                    Cookies zur Messung des Webpublikums) erforderlich sind
                    (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1
                    lit. f DSGVO gespeichert, sofern keine andere
                    Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
                    berechtigtes Interesse an der Speicherung von notwendigen
                    Cookies zur technisch fehlerfreien und optimierten
                    Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
                    Speicherung von Cookies und vergleichbaren
                    Wiedererkennungstechnologien abgefragt wurde, erfolgt die
                    Verarbeitung ausschließlich auf Grundlage dieser
                    Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
                    TTDSG); die Einwilligung ist jederzeit widerrufbar. <br />
                    <br />
                    Sie können Ihren Browser so einstellen, dass Sie über das
                    Setzen von Cookies informiert werden und Cookies nur im
                    Einzelfall erlauben, die Annahme von Cookies für bestimmte
                    Fälle oder generell ausschließen sowie das automatische
                    Löschen der Cookies beim Schließen des Browsers aktivieren.
                    Bei der Deaktivierung von Cookies kann die Funktionalität
                    dieser Website eingeschränkt sein.
                    <br />
                    <br />
                    Welche Cookies und Dienste auf dieser Website eingesetzt
                    werden, können Sie dieser Datenschutzerklärung entnehmen.
                    <br />
                    <br />
                    <Heading size='lg' my={4}>
                      Einwilligung mit Usercentrics
                    </Heading>
                    Diese Website nutzt die Consent-Technologie von
                    Usercentrics, um Ihre Einwilligung zur Speicherung
                    bestimmter Cookies auf Ihrem Endgerät oder zum Einsatz
                    bestimmter Technologien einzuholen und diese
                    datenschutzkonform zu dokumentieren. Anbieter dieser
                    Technologie ist die Usercentrics GmbH, Sendlinger Straße 7,
                    80331 München, Website: https://usercentrics.com/de/ (im
                    Folgenden „Usercentrics“).
                    <br />
                    <br /> Wenn Sie unsere Website betreten, werden folgende
                    personenbezogene Daten an Usercentrics übertragen: <br />
                    <br />
                    <ul>
                      <li>
                        Ihre Einwilligung(en) bzw. der Widerruf Ihrer
                        Einwilligung(en)
                      </li>
                      <li>Ihre IP-Adresse</li>
                      <li>Informationen über Ihren Browser</li>
                      <li>Informationen über Ihr Endgerät</li>
                      <li>Zeitpunkt Ihres Besuchs auf der Website</li>
                    </ul>{' '}
                    <br />
                    <br />
                    Des Weiteren speichert Usercentrics ein Cookie in Ihrem
                    Browser, um Ihnen die erteilten Einwilligungen bzw. deren
                    Widerruf zuordnen zu können. Die so erfassten Daten werden
                    gespeichert, bis Sie uns zur Löschung auffordern, das
                    Usercentrics-Cookie selbst löschen oder der Zweck für die
                    Datenspeicherung entfällt. Zwingende gesetzliche
                    Aufbewahrungspflichten bleiben unberührt. Der Einsatz von
                    Usercentrics erfolgt, um die gesetzlich vorgeschriebenen
                    Einwilligungen für den Einsatz bestimmter Technologien
                    einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c
                    DSGVO.
                    <Heading size='lg' my={4}>
                      Server-Log-Dateien
                    </Heading>
                    Der Provider der Seiten erhebt und speichert automatisch
                    Informationen in so genannten Server-Log- Dateien, die Ihr
                    Browser automatisch an uns übermittelt. Dies sind: <br />
                    <br />
                    <ul>
                      <li>Browsertyp und Browserversion</li>
                      <li>verwendetes Betriebssystem</li>
                      <li>Referrer URL</li>
                      <li>Hostname des zugreifenden Rechners</li>
                      <li>Uhrzeit der Serveranfrage</li>
                      <li>IP-Adresse</li>
                    </ul>{' '}
                    <br />
                    <br />
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen
                    wird nicht vorgenommen.
                    <br />
                    <br />
                    Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6
                    Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                    berechtigtes Interesse an der technisch fehlerfreien
                    Darstellung und der Optimierung seiner Website – hierzu
                    müssen die Server-Log-Files erfasst werden.
                    <br />
                    <br />
                    <Heading mt={4} size='xl'>
                      6. Kontaktformular
                    </Heading>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen,
                    werden Ihre Angaben aus dem Anfrageformular inklusive der
                    von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
                    der Anfrage und für den Fall von Anschlussfragen bei uns
                    gespeichert. Diese Daten geben wir nicht ohne Ihre
                    Einwilligung weiter. <br />
                    <br />
                    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art.
                    6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung
                    eines Vertrags zusammenhängt oder zur Durchführung
                    vorvertraglicher Maßnahmen erforderlich ist. In allen
                    übrigen Fällen beruht die Verarbeitung auf unserem
                    berechtigten Interesse an der effektiven Bearbeitung der an
                    uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder
                    auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern
                    diese abgefragt wurde; die Einwilligung ist jederzeit
                    widerrufbar. <br />
                    <br />
                    Die von Ihnen im Kontaktformular eingegebenen Daten
                    verbleiben bei uns, bis Sie uns zur Löschung auffordern,
                    Ihre Einwilligung zur Speicherung widerrufen oder der Zweck
                    für die Datenspeicherung entfällt (z. B. nach
                    abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
                    gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen
                    – bleiben unberührt. <br />
                    <br />
                    Bei Anfragen von Firmen im Rahmen der
                    Arbeitnehmerüberlassung oder Personalvermittlung werden die
                    erhobenen Daten in unserem Customer-Relationship-Management
                    System gespeichert. Wir verwenden das CRM-System „zvoove
                    (für Personaldienstleister)“, des Anbieters zvoove Group
                    GmbH, von-Humboldt-Str. 2, 49835 Wietmarschen-Lohne. Hierzu
                    haben wir mit LANDWEHR einen
                    Auftragsdatenverarbeitungsvertrag gem. Art. 28 DSGVO
                    geschlossen. <br />
                    <br />
                    Die von Ihnen angegebenen Daten werden nach Erledigung Ihrer
                    Kontaktanfrage gelöscht. Sollte es zu einem
                    Vertragsverhältnis (Arbeitnehmerüberlassungsvertrag oder
                    Personalvermittlungsvertrag) zwischen der anfragenden Firma
                    und uns kommen, werden die erhobenen Daten erst gelöscht,
                    sobald sie für ihre Zweckbestimmung nicht mehr erforderlich
                    sind und der Löschung keine gesetzlichen
                    Aufbewahrungspflichten entgegenstehen. Gesetzliche
                    Aufbewahrungspflichten ergeben sich aus handelsrechtlicher
                    und steuerrechtlicher Sicht gem. § 257 HGB und § 147 AO für
                    6 bis 10 Jahre. <br />
                    <br />
                    Mit Kontaktanfragen, die uns per Post, per E-Mail oder per
                    Telefon erreichen, wird entsprechend verfahren. <br />
                    <br />
                    Bitte beachten Sie, dass bei der normalen unverschlüsselten
                    Kommunikation über das Internet (z. B. per E-Mail) keine
                    vollständige Vertraulichkeit und Datensicherheit
                    gewährleistet ist. Wir empfehlen daher bei vertraulichen
                    Informationen die verschlüsselte Kommunikation (z. B. per
                    Bewerbungsformular) oder den Postweg. <br />
                    <br />
                    <Heading mt={4} size='xl'>
                      7. Bewerbungsprozess
                    </Heading>
                    Als Personaldienstleister werden wir von Unternehmen im
                    Rahmen der Arbeitnehmerüberlassung oder Personalvermittlung
                    beauftragt, passendes Personal für die im Unternehmen zu
                    besetzende Stelle zu finden.
                    <br />
                    <br />
                    Um diese Anfragen bedienen zu können, schaltet die kdw HR
                    GmbH & Co. KG Stellenanzeigen. Zur Anzeige dieser
                    Stellenanzeigen nutzen wir das Bewerbermanagementsystem
                    Zvoove Recruit des Dienstleisters zvoove Group GmbH,
                    von-Humboldt-Str. 2, 49835 Wietmarschen-Lohne. Der
                    Dienstleister wurde in Anlehnung an die Vorgaben der DSGVO
                    ausgewählt und ein Vertrag zur Auftragsverarbeitung wurde
                    geschlossen. Das System bietet die Möglichkeit zur
                    Erstellung einer Karriereseite, auf der die Anzeigen
                    ausgespielt werden und auf diese Sie von unserer Webseite
                    weitergeleitet werden.
                    <br />
                    <br />
                    Die Karriereseite erreichen Sie über diesen Link:
                    https://kdw-partner.europersonal.com/ und die entsprechende
                    Datenschutzerklärung finden Sie hier:
                    https://www.europersonal.com/datenschutz Informationen zur
                    dort stattfindenden Datenverarbeitung und Ihren Rechten als
                    betroffene Person finden Sie außerdem beim ersten Besuch und
                    über die verlinkte Datenschutzerklärung direkt auf der
                    Karriereseite.
                    <br />
                    <br />
                    Über die Verarbeitung Ihrer Bewerbung unter anderem über
                    unser Bewerbungsformular auf unserer Homepage informieren
                    wir gesondert an dieser Stelle: Informationen zu der
                    Verarbeitung von Bewerberdaten.
                    <Heading mt={4} size='xl'>
                      8. Analyse-Tools und Werbung
                    </Heading>
                    <Heading size='lg'>Google Analytics</Heading>
                    Diese Website nutzt Funktionen des Webanalysedienstes Google
                    Analytics. Anbieter ist die Google Ireland Limited
                    („Google“), Gordon House, Barrow Street, Dublin 4, Irland.{' '}
                    <br />
                    <br />
                    Google Analytics ermöglicht es dem Websitebetreiber, das
                    Verhalten der Websitebesucher zu analysieren. Hierbei erhält
                    der Websitebetreiber verschiedene Nutzungsdaten, wie z. B.
                    Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und
                    Herkunft des Nutzers. Diese Daten werden dem jeweiligen
                    Endgerät des Users zugeordnet. Eine Zuordnung zu einer
                    User-ID erfolgt nicht. <br />
                    <br />
                    Des Weiteren können wir mit Google Analytics u. a. Ihre
                    Maus- und Scrollbewegungen und Klicks aufzeichnen. Ferner
                    verwendet Google Analytics verschiedene
                    Modellierungsansätze, um die erfassten Datensätze zu
                    ergänzen und setzt Machine-Learning-Technologien bei der
                    Datenanalyse ein. <br />
                    <br />
                    Google Analytics verwendet Technologien, die die
                    Wiedererkennung des Nutzers zum Zwecke der Analyse des
                    Nutzerverhaltens ermöglichen (z. B. Cookies oder
                    Device-Fingerprinting). Die von Google erfassten
                    Informationen über die Benutzung dieser Website werden in
                    der Regel an einen Server von Google in den USA übertragen
                    und dort gespeichert. <br />
                    <br />
                    Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer
                    Einwilligung nach Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
                    TTDSG. Die Einwilligung ist jederzeit widerrufbar. <br />
                    <br />
                    Die Datenübertragung in die USA wird auf die
                    Standardvertragsklauseln der EU-Kommission gestützt. Details
                    finden Sie hier:
                    https://privacy.google.com/businesses/controllerterms/mccs/.
                    Das Unternehmen verfügt über eine Zertifizierung nach dem
                    „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein
                    Übereinkommen zwischen der Europäischen Union und den USA,
                    der die Einhaltung europäischer Datenschutzstandards bei
                    Datenverarbeitungen in den USA gewährleisten soll. Jedes
                    nach dem DPF zertifizierte Unternehmen verpflichtet sich,
                    diese Datenschutzstandards einzuhalten. Weitere
                    Informationen hierzu erhalten Sie vom Anbieter unter
                    folgendem Link:
                    https://www.dataprivacyframework.gov/s/participant-search/participant-
                    detail?contact=true&id=a2zt000000001L5AAI&status=Active{' '}
                    <br />
                    <br />
                    <Heading mt={4} size='lg'>
                      Browser Plugin
                    </Heading>
                    Sie können die Erfassung und Verarbeitung Ihrer Daten durch
                    Google verhindern, indem Sie das unter dem folgenden Link
                    verfügbare Browser-Plugin herunterladen und installieren:
                    https://tools.google.com/dlpage/gaoptout?hl=de. 9 Mehr
                    Informationen zum Umgang mit Nutzerdaten bei Google
                    Analytics finden Sie in der Datenschutzerklärung von Google:
                    https://support.google.com/analytics/answer/6004245?hl=de.
                  </Box>
                </Stack>
              )}
            </InViewTrigger>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default IndexPage;
